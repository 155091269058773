.g6-table-cell {
  background-color: transparent !important;
}

.g6-table-cell::before {
  display: none;
}

.g6-table-thead>tr>th {
  border-bottom: 1px solid #2a2a2a !important;
}

.g6-table-thead .active {
  color: #ffffff !important;
}

.g6-table-tbody>tr>td {
  border-bottom: none !important;
}

.reset-table .g6-table-thead th:first-of-type .g6-table-column-sorters {
  @apply justify-start !important;
}

.reset-table .g6-table-thead th .g6-table-column-sorters {
  @apply justify-end;
}

.reset-table .g6-table-thead th .g6-table-column-sorters .g6-table-column-title {
  flex: none;
}

.reset-table .g6-table-thead .g6-table-cell {
  font-weight: 500;
}