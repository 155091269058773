/* 设置滑块的轨道 */
.custom-range {
  -webkit-appearance: none;
  /* 移除默认样式 */
  width: 100%;
  /* 设定宽度 */
  height: 60px;
  /* 轨道高度 */
  background: #1E1E1E;
  /* 轨道背景色 */
  border-radius: 45px;
  /* 轨道圆角 */
  outline: none;
  /* 移除边框 */
}

/* 设置滑块的滑动按钮 */
.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* 移除默认样式 */
  appearance: none;
  width: 76px;
  /* 滑块宽度 */
  height: 60px;
  /* 滑块高度 */
  background-color: #483FFF;
  /* 滑块背景色 */
  border-radius: 43px;
  /* 滑块圆角 */
  cursor: pointer;
  position: relative;
  z-index: 10;
  background-image:
    url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.1643 7.00006L7.95716 0.792969L6.54296 2.20718L11.3359 7.00006L6.54296 11.793L7.95716 13.2072L14.1643 7.00006ZM8.51436 7.00006L2.30728 0.792969L0.893066 2.20718L5.68596 7.00006L0.893066 11.793L2.30728 13.2072L8.51436 7.00006Z" fill="white"/></svg>');
  /* 你的 SVG 数据，确保编码格式正确 */
  background-repeat: no-repeat;
  background-position: center;
}

.custom-range:disabled::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* 移除默认样式 */
  appearance: none;
  width: 76px;
  /* 滑块宽度 */
  height: 60px;
  /* 滑块高度 */
  background-color: #2F2F2F !important;
  /* 滑块背景色 */
  border-radius: 43px;
  /* 滑块圆角 */
  cursor: pointer;
  position: relative;
  z-index: 10;
  background-image:
    url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.1643 7.00006L7.95716 0.792969L6.54296 2.20718L11.3359 7.00006L6.54296 11.793L7.95716 13.2072L14.1643 7.00006ZM8.51436 7.00006L2.30728 0.792969L0.893066 2.20718L5.68596 7.00006L0.893066 11.793L2.30728 13.2072L8.51436 7.00006Z" fill="white"/></svg>');
  /* 你的 SVG 数据，确保编码格式正确 */
  background-repeat: no-repeat;
  background-position: center;
}

/* Firefox 的滑块样式 */
.custom-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #4CAF50;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
}

/* Firefox 的轨道样式 */
.custom-range::-moz-range-track {
  height: 6px;
  background: #ddd;
  border-radius: 5px;
}