html,
body {
  @apply w-full h-full bg-primaryBackground font-Poppins;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  -webkit-overflow-scrolling: touch;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  @apply w-full;
  height: var(--tg-viewport-height);
  min-height: 90%;
  -webkit-overflow-scrolling: touch;
}

.focus-container {
  @apply w-full;
  height: var(--tg-viewport-stable-height);
}

.container-center {
  @apply mx-auto max-w-[1440px];
}

@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
  .safe-bottom {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: content-box;
  }
}

.information-carousel .swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
