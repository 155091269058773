@font-face {
  font-family: 'ChillPixelsMatrix';
  src:
    url('./ChillPixels-Matrix.woff2') format('woff2'),
    url('./ChillPixels-Matrix.woff') format('woff'),
    url('./ChillPixels-Matrix.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'ChillPixelsMaximal';
  src:
    url('./ChillPixels-Maximal.woff2') format('woff2'),
    url('./ChillPixels-Maximal.woff') format('woff'),
    url('./ChillPixels-Maximal.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'ChillPixelsMinimalism';
  src:
    url('./ChillPixels-Minimalism.woff2') format('woff2'),
    url('./ChillPixels-Minimalism.woff') format('woff'),
    url('./ChillPixels-Minimalism.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'ChillPixelsMono';
  src:
    url('./ChillPixels-Mono.woff2') format('woff2'),
    url('./ChillPixels-Mono.woff') format('woff'),
    url('./ChillPixels-Mono.otf') format('opentype');
  font-display: swap;
}
