.g6-spin-container::after {
  @apply !backdrop-blur-lg;
  background-color: transparent !important;
}

.new-coin .g6-upload-select {
  border-style: solid !important;
  width: 123px !important;
  height: 138px !important
}

/* .g6-upload-select {
  border: 1px solid #2c2c2c !important;
  height: 140px !important;
} */

.coins .g6-col {
  @apply !h-full;
}

.coins .g6-list-item {
  @apply !h-full;

  padding-block: 4px !important;
}

.s-enter-active {
  z-index: 9999 !important;
}

.g6-select-focused .g6-select-selection-item {
  color: #000 !important;
}

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
}

.marquee-inner {
  display: inline-block;
  animation: marquee linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

.marquee-inner span {
  display: inline-block;
  padding-right: 1rem;
}

#ton-connect-button button {
  @apply !h-[36px];
  /* height: 48px !important; */
}

.antModel .g6-modal-content {
  padding: 18px 18px 24px 24px;
}

@media only screen and (max-width: 767px) {
  .antModel .g6-modal-content {
    padding: 18px;
  }
}

/* .popover-offset {
  inset: 0 !important;
  top: 143px !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
} */

.tradeTable .g6-table-thead .g6-table-cell {
  padding-top: 0;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
}

.tradeTable .g6-table-thead .g6-table-cell:first-child,
.tradeTable .g6-table-tbody .g6-table-row .g6-table-cell:first-child {
  padding-left: 16px;
}

.tradeTable .g6-table-thead .g6-table-cell:last-child,
.tradeTable .g6-table-tbody .g6-table-row .g6-table-cell:last-child {
  padding-right: 16px;
}

.tradeTable .g6-table-tbody .g6-table-row:last-child .g6-table-cell {
  border: 0;
}

.tradeTable .g6-table-tbody .g6-table-row .g6-table-cell {
  font-size: 12px;
}

.profileTabs .g6-tabs>.g6-tabs-nav .g6-tabs-nav-operations {
  display: none;
}

.profileTabs .g6-tabs-nav-list {
  width: 100%;
}

.profileTabs .g6-tabs-tab {
  width: 25%;
  justify-content: center;
}

video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-play-button {
  display: none !important;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.error-modal .ant-btn-primary {
  @apply !bg-primary;
}

.error-modal .ant-btn-primary:hover {
  background-color: rbga(0, 0, 0, 0.7) !important;
}

.error-modal .ant-modal-confirm-content {
  @apply text-[16px];
}

.video-background video {
  @apply w-full h-full object-fill md:object-cover;
}

.video-background .video-react {
  @apply w-full h-full;
}

.g6-app-dropdown {
  @apply !px-[6px];
}

.g6-app-dropdown .g6-dropdown-menu-item {
  @apply !py-[12px] !text-[18px];
}

.app-tonconnect button {
  @apply !w-full flex justify-center items-center text-center;
  width: calc(100vw - 24px) !important;
}